.angebot {
    font-family: ag-book, sans-serif;

    &__karte {
        iframe+div {
            border-color: black !important;
        }
    }

    &__menue-punkt {
        color: black;
        text-decoration: none;
    }

    &__swiper {
        position: relative;
    }

    &__stichwort-extra {
        font-weight: 500;
    }

    &__detail-link {

        &--1 {
            color: black;
            text-decoration: none;
        }
    }
}


@include mediaquery(smartphone) {


    $layout: smartphone;

    .swiper {
        width: 100%;

        &-slide {
            margin-right: groesse($layout, 10);
        }
    }

    .angebot {

        &__zurueck {
            display: none;
        }

        &__pfeil {

            &--rechts {
                @include abstand($layout, margin, right, 20);
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                width: groesse($layout, 30);
                z-index: 1;
            }

            &-svg {
                width: 100%;
            }
        }

        &__swiper {
            @include abstand($layout, padding, left, 20);
        }

        &__slide {
            &--quer {
                height: calc(100vw / 12 * 9 / 4 * 3);
                width: calc(100vw / 12 * 9);
            }

            &--hoch {
                height: calc(100vw / 12 * 9 / 4 * 3);
                width: calc((100vw / 12 * 9 / 4 * 3) / 4 * 3);
            }
        }

        &__container {
            @include abstand($layout, margin, alle, 0, 40, 0, 40);
        }

        &__spalte {
            &--1 {
                @include abstand($layout, margin, alle, 0, 0, -20, 0);
            }

            &--2 {
                display: none;
            }
        }

        &__haupttitel {
            @include abstand($layout, margin, alle, 0, 0, 20, 0);
            @include abstand($layout, padding, alle, 0, 0, 10, 0);
            @include schriftart($layout, 19, 23, 500);
            border-bottom: groesse($layout, 4) solid black;
        }

        &__titel {
            @include abstand($layout, margin, alle, 20, 0, 0, 0);
            @include schriftart($layout, 32, 36, 500);
        }

        &__untertitel {
            @include abstand($layout, margin, alle, 0, 0, 40, 0);
            @include schriftart($layout, 32, 36);
        }

        &__introbild {
            @include abstand($layout, padding, alle, 0, 0, 20, 0);
            @include abstand($layout, margin, alle, 0, -20, 0, -20);
        }

        &__stichwoerter {
            @include abstand($layout, padding, alle, 20, 0, 40, 0);
        }

        &__stichwoerter-desktop {
            display: none;
        }

        &__stichwoerter-mobile {
            @include schriftart($layout, 24, 29);
        }

        &__menue {
            @include abstand($layout, padding, alle, 80, 0, 40, 0);
            @include abstand($layout, margin, alle, 0, -40, 0, -40);
            background-color: white;
            display: flex;
            justify-content: space-between;
            position: sticky;
            top: 0;
            z-index: 1;
        }

        &__menue-vermietung {
            @include abstand($layout, padding, alle, 80, 0, 40, 0);
            @include abstand($layout, margin, alle, 0, -40, 0, -40);
            background-color: white;
            position: sticky;
            top: 0;
            z-index: 1;
        }

        &__menue-punkt-vermietung {
            @include abstand($layout, padding, alle, 0, 28, 0, 0);
        }

        &__menue-punkt {
            @include schriftart($layout, 14, 17);

            &[data-aktiv="true"] {
                font-weight: 500;
            }

            &--erster {
                @include abstand($layout, margin, alle, 0, 0, 0, 40);
            }

            &--letzter {
                @include abstand($layout, margin, alle, 0, 40, 0, 0);
            }
        }

        &__detail-text {

            &--1 {
                @include schriftart($layout, 19, 23, 500);
                width: spalten($layout, 3, 5, 0, 0);
            }

            &--2 {
                @include schriftart($layout, 19, 23, 500);
                width: spalten($layout, 3, 5, 0, 0);
            }
        }

        &__abschnitt {
            border-top: groesse($layout, 4) solid black;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &--1 {
                @include abstand($layout, padding, alle, 20, 0, 40, 0);

                &-vermietung {
                    @include abstand($layout, padding, alle, 20, 0, 40, 0);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

            &--2 {
                @include abstand($layout, padding, alle, 20, 0, 40, 0);
            }

            &--3 {
                @include abstand($layout, padding, alle, 20, 0, 40, 0);
                font-weight: 500;
            }
        }

        &__abschnitt-1-zeile {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%
        }

        &__abschnitt-2-zeile {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%
        }

        &__detail-titel {

            &--1 {
                @include schriftart($layout, 15, 23);
                width: spalten($layout, 2, 5, 0, -1);
            }

            &--2 {
                @include schriftart($layout, 15, 23);
                width: spalten($layout, 2, 5, 0, -1);
            }
        }

        &__detail-link--1 {
            @include schriftart($layout, 24, 29);
        }

        &__beschreibung {
            @include abstand($layout, padding, alle, 80, 0, 40, 0);
            border-top: groesse($layout, 4) solid black;

            & h2 {
                @include schriftart($layout, 24, 29);
                @include abstand($layout, margin, alle, 0, 0, 40, 0);
                font-weight: 400;
            }

            p {
                @include schriftart($layout, 18, 23);
                @include abstand($layout, margin, alle, 0, 0, 25, 0);
            }
        }

        &__bilder {
            @include abstand($layout, margin, alle, 0, -20, 0, -20);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__bilder-einzeln {
            @include abstand($layout, margin, alle, 0, -20, 0, -20);
        }

        &__bild {
            width: 100%;
            @include abstand($layout, margin, bottom, 40);

            &--quer {
                width: 100%;
            }

            &--hoch {
                width: spalten($layout, 10, 20, 0, -1);

            }

            &--hoch-2 {
                margin-left: spalten($layout, 0, 20, 0, 4);
                width: spalten($layout, 10, 20, 0, -1);
            }

            &--hoch-einzeln {
                margin-left: spalten($layout, 1, 5, 0, 2.2);
                width: spalten($layout, 3, 5, 0.6, -2);
            }
        }

        &__ort {
            @include abstand($layout, padding, alle, 40, 0, 80, 0);
            width: 100%;
        }

        &__beschreibung-ort {
            @include abstand($layout, padding, alle, 80, 0, 40, 0);
            border-top: groesse($layout, 4) solid black;

            & h2 {
                @include schriftart($layout, 24, 29);
                @include abstand($layout, margin, alle, 0, 0, 40, 0);
                font-weight: 400;
            }

            p {
                @include schriftart($layout, 18, 23);
                @include abstand($layout, margin, alle, 0, 0, 25, 0);
            }
        }


        &__karte {
            @include abstand($layout, margin, alle, 0, -20, 0, -20);
            height: groesse($layout, 535);
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .swiper {
        @include abstand($layout, margin, alle, -230, 0, 72, 0);
        width: 100%;

        &-slide {
            margin-right: groesse($layout, 68);
        }
    }

    .angebot {
        @include abstand($layout, margin, alle, 60, 310, 0, 0);

        &__zurueck {
            @include abstand($layout, margin, left, 85);
            cursor: pointer;
            height: groesse($layout, 70);
            position: fixed;
            width: groesse($layout, 30);
            z-index: 100;
        }

        &__zurueck-svg {
            cursor: pointer;
            height: groesse($layout, 70);
            position: fixed;
            width: groesse($layout, 30);
        }

        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__spalte {
            &--1 {
                @include abstand($layout, padding, bottom, 230);
                width: spalten($layout, 8, 10, 0, 0);
            }

            &--2 {
                width: spalten($layout, 2, 10, 0, 0);
            }
        }

        &__haupttitel {
            @include abstand($layout, padding, bottom, 8);
            @include schriftart($layout, 29, 34, 500);
            border-bottom: groesse($layout, 6) solid black;
            display: inline;
            margin-left: spalten($layout, 1, 8, 0, 1);
        }

        &__inhalt {
            width: 100%
        }

        &__titel {
            @include abstand($layout, margin, top, 30);
            @include schriftart($layout, 56, 60, 500);
            margin-left: spalten($layout, 1, 8, 0, 1);
        }

        &__untertitel {
            @include abstand($layout, margin, bottom, 50);
            @include schriftart($layout, 56, 60);
            margin-left: spalten($layout, 1, 8, 0, 1);
        }

        &__swiper {
            padding-left: spalten($layout, 2, 20, 0, 1);
            padding-right: spalten($layout, 2, 20, 0, 1);
        }

        &__slide {
            &--quer {
                // Die Grösen können sich nur auf den viewport beziehen und nicht wie sonst auf das parent Element,
                // weil die Höhe sich nicht als Verhältnis zur Breite des parent Elements darstellen kässt
                // width
                // vw geteilt durch Spalten (Spalten stark erhöht, weil das Bild aus dem normalen Raster fällt)
                // mal Spaltenbreite des Bildes
                // height
                // Weil das Bildverhältnis 4:3 ist, ist die Höhe gleich der Breite durch 4 mal 3
                height: calc(100vw / 48 * 15 / 4 * 3);
                width: calc(100vw / 48 * 15);
            }

            &--hoch {
                height: calc(100vw / 48 * 15 / 4 * 3);
                width: calc((100vw / 48 * 15 / 4 * 3) / 4 * 3);
            }
        }

        &__pfeil {

            &--rechts {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                width: groesse($layout, 30);
                z-index: 1;
            }
        }

        &__pfeil-svg {
            width: 100%;
        }

        &__introbild {
            @include abstand($layout, margin, bottom, 40);
            margin-left: spalten($layout, 1, 8, 0, 1);
            width: spalten($layout, 7, 8, 0, 0);
        }

        &__stichwoerter-desktop {
            @include abstand($layout, padding, bottom, 57);
            @include schriftart($layout, 40, 46);
            padding-left: spalten($layout, 0, 48, 0, 3);
            position: sticky;
            top: 0;
            z-index: 1;
        }

        &__stichwoerter-mobile {
            display: none;
        }

        &__stichwort-extra {
            @include schriftart($layout, 18, 24);
        }

        &__menue {
            @include abstand($layout, padding, alle, 75, 0, 75, 0);
            @include schriftart($layout, 28, 34);
            background-color: white;
            display: flex;
            margin-right: spalten($layout, -4, 15, 0, 2.4);
            padding-left: spalten($layout, 1, 8, 0, 1);
            position: sticky;
            top: 0;
            z-index: 1;
        }

        &__menue-vermietung {
            @include abstand($layout, padding, alle, 75, 0, 75, 0);
            @include schriftart($layout, 28, 34);
            background-color: white;
            display: flex;
            margin-right: spalten($layout, -4, 15, 0, 2.4);
            padding-left: spalten($layout, 1, 8, 0, 1);
            position: sticky;
            top: 0;
            z-index: 1;
            width: 100%;
        }


        &__menue-punkt {
            @include abstand($layout, margin, right, 40);

            &:hover,
            &[data-aktiv="true"] {
                font-weight: 500;
            }

            &--aktiv {
                font-weight: 500;
            }

            &--details {
                width: groesse($layout, 90);
            }

            &--beschrieb {
                width: groesse($layout, 130);
            }

            &--bilder {
                width: groesse($layout, 78);
            }

            &--ort {
                width: groesse($layout, 45);
            }

            &--kontakt {
                width: groesse($layout, 105);
            }
        }

        &__details {
            display: flex;
            margin-right: spalten($layout, -2, 8, 4, 0);
        }

        &__abschnitt {
            @include abstand($layout, margin, top, 145);
            border-top: groesse($layout, 6) solid black;

            &--1 {
                @include abstand($layout, padding, alle, 40, 0, 170, 0);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: spalten($layout, 1, 10, 0, 1);
                width: spalten($layout, 3, 10, 0, -1);
            }

            &--1-vermietung {
                @include abstand($layout, padding, alle, 40, 0, 0, 0);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: spalten($layout, 1, 10, 0, 1);
                width: spalten($layout, 3, 10, 0, -1);
            }

            &--2 {
                @include abstand($layout, padding, alle, 40, 0, 170, 0);
                margin-left: spalten($layout, 0, 16, 0, 3);
                width: spalten($layout, 3, 10, 0, -1);
            }

            &--3 {
                @include abstand($layout, padding, alle, 40, 0, 170, 0);
                margin-left: spalten($layout, 0, 16, 0, 3);
                width: spalten($layout, 2, 10, 0, 0);
            }
        }


        &__detail-titel {

            &--1 {
                @include schriftart($layout, 18, 30);
                margin-top: groesse($layout, 30);
                width: spalten($layout, 1, 3, 1, 1);

                &:first-child {
                    margin-top: 0;
                }
            }

            &--2 {
                @include schriftart($layout, 18, 23);
                width: spalten($layout, 1, 3, 1, 1);
            }
        }

        &__detail-text {

            &--1 {
                @include schriftart($layout, 28, 32);
                font-weight: 500;
                width: 50%;
            }

            &--2 {
                @include schriftart($layout, 19, 23);
                font-weight: 500;
                width: 50%;
            }
        }

        &__detail-link {

            &--1 {
                @include schriftart($layout, 39, 46);
            }
        }

        &__detail-buchung {
            @include abstand($layout, padding, alle, 40, 0, 200, 0);
            justify-content: flex-end;
        }

        &__abschnitt-1-zeile {
            margin-bottom: groesse($layout, 30);
        }

        &__abschnitt-2-zeile {
            display: flex;
            flex-wrap: wrap;
        }

        &__beschreibung {
            @include abstand($layout, padding, alle, 80, 0, 0, 0);
            @include schriftart($layout, 28, 34);
            border-top: groesse($layout, 6) solid black;
            margin-left: spalten($layout, 1, 8, 0, 1);
            width: spalten($layout, 6, 8, 0, -1);

            & h2 {
                @include abstand($layout, margin, alle, 0, 0, 80, 0);
                @include schriftart($layout, 39, 46);
                font-weight: 400;
            }

            p {
                @include abstand($layout, margin, alle, 0, 0, 35, 0);
                @include schriftart($layout, 28, 34);
            }

            &--verkauf {
                @include abstand($layout, margin, bottom, (170 + 30));
            }
        }

        &__beschreibung-ort {
            @include abstand($layout, padding, alle, 80, 0, 0, 0);
            @include schriftart($layout, 28, 34);
            border-top: groesse($layout, 6) solid black;
            margin-left: spalten($layout, 1, 10, 0, 1);
            width: spalten($layout, 15, 20, 0, 1);

            & h2 {
                @include abstand($layout, margin, alle, 0, 0, 80, 0);
                @include schriftart($layout, 39, 46);
                font-weight: 400;
            }

            p {
                @include abstand($layout, margin, alle, 0, 0, 35, 0);
                @include schriftart($layout, 28, 34);
            }
        }

        &__bilder {
            @include abstand($layout, margin, alle, 165, 0, 160, 0);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: spalten($layout, 1, 10, 6, 4);
            margin-right: spalten($layout, -4, 15, 0, 2.5);

            &-einzeln {
                @include abstand($layout, margin, alle, 200, 0, 235, 0);
                padding-left: spalten($layout, 1, 10, 6, 4);
            }
        }

        &__bild {
            @include abstand($layout, margin, bottom, 80);

            &--quer {
                width: 100%;
            }

            &--hoch {
                width: spalten($layout, 10, 20, 0.1, -1);

                &-2 {
                    margin-left: spalten($layout, 0, 20, 0, 4);
                    width: spalten($layout, 10, 20, 0, -1);
                }

                &-einzeln {
                    margin-left: spalten($layout, 1.5, 7, 0, 1);
                    width: spalten($layout, 4, 7, 0, 0);
                }
            }
        }

        &__ort {
            @include abstand($layout, padding, alle, 0, 0, 240, 0);
            margin-left: spalten($layout, 1, 20, 0, -1.1);
        }

        &__karte {
            @include abstand($layout, margin, alle, 40, 0, 0, 0);
            height: groesse($layout, 770);
            margin-left: spalten($layout, 2, 20, 0, 1);
            margin-right: spalten($layout, -4, 15, 0, 1.8);
        }
    }
}
.startseite {
    display: flex;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
    justify-content: flex-end;

    &__video-container {
        background-size: cover;
        height: 100%;
        position: absolute;
        left: 0;
        overflow: hidden;
        top: 0;
        width: 100%;
        z-index: -1;

        &::after {
            content: "";
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), ease-in-out, transparent);
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }
    }

    &__video {
        display: block;
        height: 100%;
        object-position: center center;
        object-fit: cover;
        transition: filter 0.5s;
        width: 100%;
    }

    &__blurbild {
        display: block;
        filter: blur(80px);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(1.25);
        width: 100%;
        z-index: -1;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .startseite {
        @include abstand($layout, padding, alle, 0, 40, 0, 40);

        &__video-container {

            &::after {
                height: groesse($layout, 260);
            }
        }

        &__text {
            @include abstand($layout, padding, alle, 0, 0, 40, 0);
            width: spalten($layout, 4, 5, 0, 0);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .startseite {

        &__video-container {
            &::after {
                height: groesse($layout, 500);
            }
        }

        &__text {
            @include abstand($layout, padding, alle, 0, 0, 140, 0);
            margin-left: groesse($layout, 150);
            width: groesse($layout, 515);
        }
    }
}
.kontaktformular {
    font-family: ag-book, sans-serif;

    &__label {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__input {
        border-radius: 0;
        font-family: 'Source Serif Pro', serif;
        width: 100%;
    }

    &__label {
        cursor: pointer;
    }

    &__kontaktfelder {
        width: 100%;
    }

    &__input {
        border: 0;
        background: transparent;
        border-bottom: 2px solid black;
        font-family: ag-book, sans-serif;
        outline: 0;
    }

    &__bild {
        overflow: hidden;
        border-radius: 100%;
    }

    &__bestaetigung {
        display: none;
    }

    &__button {
        cursor: pointer;
    }

    a[href^=tel],
    a[href^=mailto] {
        color: black;
        text-decoration: none;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .kontaktformular {
        
        &__label {
            @include abstand($layout, padding, top, 15);
            @include schriftart($layout, 20, 28);
        }
        
        &__abschnitt {
            @include abstand($layout, margin, alle, 0, 0, 20, 0);
            @include schriftart($layout, 20, 28);
        }
        
        &__text {
            @include schriftart($layout, 24, 29);
        }
        
        &__bild {
            @include abstand($layout, margin, alle, 40, 0, 40, 0);
            width: spalten($layout, 3, 10, 0, 1);
        }
        
        &__kontakt {
            &-name {
                @include schriftart($layout, 23, 29);
                font-weight: 500;
                width: 100%;
            }
            
            &-angaben {
                @include schriftart($layout, 22, 29);
                width: 100%;
            }
        }
        
        &__formular {
            @include abstand($layout, margin, alle, 60, 0, 114, 0);
        }
        
        &__label {
            @include schriftart($layout, 18, 28);
        }
        
        &__input {
            @include schriftart($layout, 27, 36);
            font-weight: 500;
        }
        
        &__button {
            @include abstand($layout, margin, alle, 85, 0, 0, 0);
            @include abstand($layout, padding, alle, 0, 20, 0, 20);
            @include schriftart($layout, 26, 40);
            background-color: transparent;
            border: 2px solid black;
            outline: 0;
        }
        
        &__bestaetigung {
            @include abstand($layout, margin, alle, 85, 0, 0, 0);
            @include schriftart($layout, 24, 29);
        }
    }
}


@include mediaquery(desktop) {
    $layout: desktop;

    .kontaktformular {
        @include abstand($layout, padding, alle, 80, 0, 300, 0);
        border-top: groesse($layout, 6) solid black;
        display: flex;
        justify-content: space-between;
        margin-left: spalten($layout, 1, 10, 6, 4);
        width: spalten($layout, 15, 20, 0, -1);
        
        &__label {
            @include schriftart($layout, 18, 18);
        }
        
        &__info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: spalten($layout, 6, 12, 0, -1);
        }

        &__formular {
            width: spalten($layout, 6, 12, 0, -1);
        }

        &__text {
            @include schriftart($layout, 28, 34);
        }

        &__person {
            display: flex;
            flex-wrap: wrap;
        }
        
        &__bild {
            align-items: flex-end;
            width: spalten($layout, 3, 6, 0, 1);
        }
        
        &__kontakt {
            @include abstand($layout, margin, alle, 50, 0, 0, 0);
            @include schriftart($layout, 29, 34);
            width: 100%;
            
            &-name {
                font-weight: 500;
            }
        }

        &__kontaktfelder:not(:first-of-type) {
            @include abstand($layout, margin, top, 25);
        }
        
        &__input {
            @include schriftart($layout, 29, 40);
            font-weight: 500;
        }
        
        &__button {
            @include abstand($layout, margin, top, 85);
            @include schriftart($layout, 29, 45);
            background-color: transparent;
            border: 2px solid black;
            outline: 0;
            width: spalten($layout, 1, 3, 0, 1);
            
        }
        
        &__bestaetigung {
            @include abstand($layout, margin, top, 85);
            @include schriftart($layout, 28, 34);
        }
    }
}

.innenarchitektur {

    &__bildzeile {
        display: flex;
        justify-content: space-between;
    }
    
    &__sticky {
        height: 0;
        position: sticky;
        z-index: 1;
    }

    &__text {
        background-color: white;
        width: 100%;

        &-beschreibung {
            font-family: "Kievit Serif W01 Regular", serif;
        }
        
        &-ort {
            font-family: ag-book, sans-serif;
            font-weight: 500;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .innenarchitektur {
        @include abstand($layout, margin, alle, 0, 0, 100, 0);
        width: 100%;

        &__sticky {
            top: calc(100vh - groesse($layout, 105))
        }

        &__bilder {
            width: 100%;
        }

        &__bild-desktop {
            display: none;
        }
        
        &__bildzeile {
            @include abstand($layout, margin, alle, 10, 0, 0, 0);

            &--quer-quer {

                & .innenarchitektur__bild {
                    width: spalten($layout, 5, 10);
                }
            }

            &--quer,
            &--hoch {
                & .innenarchitektur__bild {
                    width: 100%;
                }
            }

            &--quer-hoch,
            &--hoch-quer {

                & .innenarchitektur__bild-mobile--quer {
                    width: spalten($layout, 7, 10);
                }

                & .innenarchitektur__bild-mobile--hoch {
                    width: spalten($layout, 3, 10);
                }
            }
        }

        &__text {
            display: none;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .innenarchitektur {
        @include abstand($layout, padding, alle, 0, 0, 300, 0);

        &__bilder {
            @include abstand($layout, padding, alle, 0, 310, 20, 0);
            width: 100%;
        }

        &__bild-mobile {
            display: none;
        }

        &__bildzeile {
            @include abstand($layout, padding, alle, 16, 0, 0, 0);

            &--quer-quer {
                & .innenarchitektur__bild {
                    width: spalten($layout, 5, 10);
                }
            }

            &--quer,
            &--hoch {
                & .innenarchitektur__bild {
                    width: 100%;
                }
            }

            &--quer-hoch,
            &--hoch-quer {
                & .innenarchitektur__bild-desktop--quer {
                    width: spalten($layout, 7, 10);
                }

                & .innenarchitektur__bild-desktop--hoch {
                    width: spalten($layout, 3, 10);
                }
            }

        }

        &__sticky {
            top: calc(100vh - groesse($layout, 70))
        }

        &__text {
            @include abstand($layout, padding, alle, 20, 0, 30, 0);
            align-items: center;
            display: flex;
            height: groesse($layout, 70);
            justify-content: space-between;
            padding-left: spalten($layout, 1, 10, 0, 1);
            
            &-beschreibung {
                @include schriftart($layout, 24, 29);
            }
            
            &-ort {
                @include abstand($layout, padding, right, 20);
                @include schriftart($layout, 19, 23);
            }
        }
    }
}
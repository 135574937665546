.baute {
    &__titel {
        font-family: "Kievit Serif W01 Regular", serif;
    }
    
    &__untertitel {
        font-family: ag-book, sans-serif;
    }
    
    &__beschreibung {
        font-family: "Kievit Serif W01 Regular", serif;
    }
    
    &__details {
        font-family: ag-book, sans-serif;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .baute {

        &__name {
            @include abstand($layout, margin, alle, 40, 40, 40, 40);
        }
        
        &__titel {
            @include schriftart($layout, 32, 36);
        }
        
        &__untertitel {
            @include schriftart($layout, 19, 23, 500);
        }
        
        &__beschreibung {
            @include abstand($layout, margin, alle, 0, 40, 60, 40);
            @include schriftart($layout, 20, 25);

            & p {
                @include abstand($layout, margin, alle, 0, 0, 25, 0);
            }
        }
        
        &__details {
            @include abstand($layout, margin, alle, 60, 40, 60, 40);
            @include abstand($layout, padding, top, 20);
            border-top: groesse($layout, 4) solid black;
            
            &-zeile {
                display: flex;
                justify-content: space-between;
            } 
            
            &-titel {
                @include schriftart($layout, 18, 23);
                margin-top: groesse($layout, 30);
                width: spalten($layout, 2, 5, 0, 0);
                
                &:first-child {
                    margin-top: 0;
                }
            }
            
            &-text {
                @include schriftart($layout, 19, 23, 500);
                width: spalten($layout, 2, 5, 0, 5);
            }
        }

        &__haus {

            &--quer {
                @include abstand($layout, margin, alle, 60, 10, 60, 10);
            }
            
            &-mobile {
                @include abstand($layout, margin, alle, 60, 10, 60, 10);
            }

            &-1-mobile--quer {
                @include abstand($layout, margin, alle, 20, 10, 20, 10);
                margin-left: spalten($layout, 6, 15, 0, 1);
            }
            
            &-0-mobile--quer {
                @include abstand($layout, margin, alle, 20, 10, 20, 10);
                margin-right: spalten($layout, 6, 15, 0, 1);
            }
        }        
    }
}


@include mediaquery(desktop) {
    
    $layout: desktop;
    
    .baute {
        @include abstand($layout, margin, alle, 0, 310, 0, 0);
        display: flex;
        flex-wrap: wrap;
        
        &__bild {
            @include abstand($layout, margin, top, 60);
            
            &--quer {
                width: spalten($layout, 7, 10, 0, 0);
            }
            &--hoch {
                @include abstand($layout, margin, bottom, 120);
                margin-left: spalten($layout, 1, 10, 0, 1);
                width: spalten($layout, 6, 10, 0, 0);
            }
        }
        
        &__name--quer {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: spalten($layout, 0, 10, 0, 4);
            max-height: 100vh;
            width: spalten($layout, 3, 10, 0, 0);
        }
        
        &__name--hoch {
            @include abstand($layout, margin, bottom, 120);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: spalten($layout, 0, 10, 0, 4);
            max-height: 100vh;
            width: spalten($layout, 3, 10, 0, 0);
        }
        
        &__titel {
            @include abstand($layout, margin, alle, 20, 0, 0, 0);
            @include schriftart($layout, 60, 66);
        }
        
        &__untertitel {
            @include abstand($layout, margin, alle, 20, 0, 60, 0);
            @include schriftart($layout, 40, 46, 500);
        }
        
        &__beschreibung {
            @include abstand($layout, margin, alle, 150, 0, 0, 0);
            @include schriftart($layout, 40, 46);
            margin-left: spalten($layout, 1, 10, 0, 1);
            width: spalten($layout, 11, 20, 0, 0);
            
            & p {
                @include abstand($layout, margin, alle, 0, 0, 45, 0);
            }
        }

        &__details {
            @include abstand($layout, margin, alle, 150, 0, 0, 0);
            @include abstand($layout, padding, top, 25);
            margin-left: spalten($layout, 1, 20, 0, 4);
            position: relative;
            width: spalten($layout, 3, 10, 0, -2);
            
            &::before {
                background-color: black;
                content: "";
                height: groesse($layout, 6);
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }     
            
            &-zeile {
                display: flex;
            }
            
            &-titel {
                @include schriftart($layout, 18, 23);
                width: spalten($layout, 1, 3, 1, 2);
            }
            
            &-text {
                @include schriftart($layout, 19, 23, 500);
            }
        }

        &__haeuser {
            @include abstand($layout, margin, alle, 0, 0, 0, 0);
            width: 100%
        }

        &__haus--quer {
            @include abstand($layout, margin, alle, 300, 0, 300, 0);
            width: 100%;
        }
        
        &__haus-1--hoch {
            @include abstand($layout, margin, alle, 300, 0, 300, 0);
            margin-left: spalten($layout, 5, 10, 0, 1);
        }
        
        &__haus-1--quer {
            @include abstand($layout, margin, alle, 300, 0, 300, 0);
            margin-left: spalten($layout, 3, 10, 0, 1);
        }
        
        &__haus-0--hoch {
            @include abstand($layout, margin, alle, 300, 0, 300, 0);
            margin-right: spalten($layout, 5, 10, 0, 1);
        }
        
        &__haus-0--quer {
            @include abstand($layout, margin, alle, 300, 0, 300, 0);
            margin-right: spalten($layout, 3, 10, 0, 1);
        }
    }
}
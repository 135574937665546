@include mediaquery(smartphone) {

    $layout: smartphone;

    .body--angebote, .body--angebote-entry, .body--architektur-entry {

        .logo__container {
            background-color: white;
        }
    }

    .logo {
        width: groesse($layout, 74);

        &__container {
            @include abstand($layout, padding, alle, 20, 40, 35, 40);
            background-color: map-get($farben, hellbraun);
        }

        .body--startseite & {
            @include abstand($layout, padding, alle, 100, 40, 0, 0);
            position: absolute;
            width: groesse($layout, 200);
            z-index: 1;

            &__container {
                background-color: transparent;
                display: flex;
                justify-content: flex-end;
                padding: 0;
                width: 100%;
            }
        }

        &-svg {
            display: block;
            width: 100%;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .logo {
        display: none;
    }
}
.menue {
    font-family: ag-book, sans-serif;

    &__logo {
        display: block;
    }

    &__logo-svg {
        display: block;
        width: 100%;
    }

    &__link--aktiv {
        font-weight: 500;
    }

    &__link:hover {
        font-weight: 500;
    }
}

.body {

    &--startseite .menue__link,
    &--kontakt .menue__link {
        background-color: transparent;
        color: white;
    }

    &--startseite path,
    &--kontakt path,
    &--startseite rect,
    &--kontakt rect,
    &--kontakt #scrollpfeil {
        fill: white;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .body {

        &--kontakt {

            .menue,
            .logo__container {
                background-color: map-get($farben, braun);
            }

            .menue__hamburger .hamburger-inner,
            .menue__hamburger .hamburger-inner::after,
            .menue__hamburger .hamburger-inner::before {
                background-color: white;
            }
            
            &[data-menue-aktiv="true"] .menue__hamburger .hamburger-inner,
            &[data-menue-aktiv="true"] .menue__hamburger .hamburger-inner::after,
            &[data-menue-aktiv="true"] .menue__hamburger .hamburger-inner::before {
                background-color: white;
            }
        }
        
        &--angebote, &--angebote-entry, &--architektur-entry {
            .menue,
            .logo__container {
                background-color: white;

            }
        }

        &--startseite {

            .menue {
                background-color: white;
                padding: 0;
            }

            &[data-menue-aktiv="true"] .menue__link{
                color: black;
            }

            &[data-menue-aktiv="true"] path {
                fill: black;
            }

            .menue__hamburger .hamburger-inner,
            .menue__hamburger .hamburger-inner::after,
            .menue__hamburger .hamburger-inner::before {
                background-color: white;
            }

            .menue {
                @include abstand($layout, padding, alle, 0, 40, 0, 40);

                &__container-logo {
                    display: flex;
                    justify-content: flex-end;
                }

                &__container-links {
                    align-items: flex-end;
                    display: flex;
                }

                &__logo--mobile {
                    @include abstand($layout, margin, top, 100);
                    height: groesse($layout, 80);
                    width: groesse($layout, 160);
                }

                &__links {
                    @include abstand($layout, margin, bottom, 50);
                    justify-content: flex-end;
                }
            }
        }
    }

    .menue {
        @include abstand($layout, padding, alle, 20, 40, 50, 40);
        background-color: map-get($farben, hellbraun);
        display: none;
        height: calc(var(--vh, 1vh) * 100);
        justify-content: space-between;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;

        .body[data-menue-aktiv="true"] & {
            display: flex;
            flex-direction: column;
        }

        &__hamburger {
            @include abstand($layout, padding, alle, 20, 40, 0, 40);
            $hamburger-breite: 36px;
            $hamburger-hoehe: 29px;
            $hamburger-strichstaerke: 5px;
            $hamburger-farbe: black;
            $hamburger-farbe-aktiv: black;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            background-color: transparent;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 1001;
        }

        &__logo {

            &--mobile {
                width: groesse($layout, 74);
            }

            &--desktop {
                display: none;
            }
        }

        &__links {
            @include schriftart($layout, 46, 52);
            display: flex;
            flex-direction: column;
        }

        &__link {
            color: black;
            text-decoration: none;
        }
    }

    .hamburger {

        &-box {
            height: 35px;
            right: 0;
            width: 37px;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .body {

        &--startseite {
            .menue {
                @include abstand($layout, padding, alle, 140, 0, 140, 0);
                @include abstand($layout, margin, top, 0);
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
                position: fixed;
                top: 0;
                right: 0;
                width: groesse($layout, 484);
                z-index: 1;

                &__menue {
                    display: flex;
                    flex-direction: column;
                }

                &__logo {
                    &--desktop {
                        display: none;
                    }

                    &--startseite {
                        @include abstand($layout, margin, bottom, 0);
                        display: block;
                        padding-left: 0;
                        padding-right: spalten($layout, 1, 3, 0, 1);
                    }
                }

                &__link {
                    @include schriftart($layout, 55, 63);
                    display: inherit;
                    font-family: ag-book, sans-serif;
                    margin-bottom: 0;
                    padding-left: 0;
                    text-decoration: none;

                    &:last-child {
                        margin-bottom: -0.3vw;
                    }
                }
            }
        }
    }

    .menue {
        @include abstand($layout, margin, alle, 60, 0, 0, 0);
        position: fixed;
        top: 0;
        right: 0;
        width: groesse($layout, 310);
        z-index: 1;
        height: 100%;

        &__container {
            display: flex;
            flex-direction: column;
            height: 100vh;
            justify-content: space-between;
        }

        &__logo {
            &--desktop {
                @include abstand($layout, margin, bottom, 100);
                padding-left: spalten($layout, 1, 4, 0, 1);
                padding-right: spalten($layout, 1, 4, 0, 1);
            }

            &--mobile {
                display: none;
            }
        }

        &__link {
            @include schriftart($layout, 25, 28.75);
            color: black;
            display: inherit;
            font-family: ag-book, sans-serif;
            padding-left: spalten($layout, 1, 4, 0, 1);
            padding-right: spalten($layout, 1, 4, 0, 1);
            text-decoration: none;

            &--aktiv {
                font-weight: 500;
            }
        }

        &__hamburger {
            display: none;
        }
    }
}
.kontakt {
    font-family: ag-book, sans-serif;
    background-color: map-get($farben, braun);

    &__adresse {
        color: white;
    }

    &__person {
        color: white;
    }

    &__person-bild {
        border: 5px solid white
    }

    &__person-name {
        font-weight: 500;
    }

    a[href^=tel],
    a[href^=mailto] {
        color: white;
        text-decoration: none;
    }

    &__karte {
        iframe+div {
            border-color: black !important;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .kontakt {
        @include abstand($layout, padding, alle, 0, 40, 0, 40);


        &__adresse {
            @include abstand($layout, margin, alle, 0, 0, 40, 0);
            @include schriftart($layout, 20, 23);

            h4 {
                font-weight: 500;
            }
        }

        &__person-bild {
            @include abstand($layout, margin, alle, 0, 0, 20, 0);
        }

        &__person {
            @include abstand($layout, padding, alle, 0, 0, 60, 0);
            @include schriftart($layout, 19, 23);
        }

        &__karte {
            height: groesse($layout, 535);
            @include abstand($layout, margin, alle, 40, -30, 40, -30);
        }

        &__team-container {
            @include abstand($layout, padding, alle, 80, 0, 0, 0);
        }

        &__person-telefon,
        &__person-titelberuf {
            @include abstand($layout, margin, bottom, 9);
        }

    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .kontakt {

        @include abstand($layout, padding, alle, 80, 310, 300, 0);

        &__adresse {
            @include schriftart($layout, 28, 34);
            margin-left: spalten($layout, 2, 20, 0, 1);
            padding-right: spalten($layout, 4, 10, 0, 1);

            & h4 {
                @include schriftart($layout, 29, 34, 500);
            }
        }

        &__karte {
            @include abstand($layout, margin, alle, 110, 0, 110, 0);
            height: groesse($layout, 770);
            margin-left: spalten($layout, 2, 20, 0, 1);
        }

        &__team {

            &--1 {
                margin-left: spalten($layout, 2, 20, 0, 1);
                margin-right: spalten($layout, 4, 10, 0, 1);
            }

            &--0 {
                margin-left: spalten($layout, 5, 10, 0, 1);
            }

            &:last-child {

                .kontakt__person-text {
                    // damit der letzte Text den richtigen padding hat.
                    position: relative;
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }

        &__person-bild {
            @include abstand($layout, margin, alle, 00, 0, 00, 0);

        }

        &__person {
            @include schriftart($layout, 28, 34);
            width: spalten($layout, 3, 5);

            &--0 {
                margin-left: spalten($layout, 2, 5, 0, 1);
            }
        }

        &__person-name {
            @include schriftart($layout, 29, 34);
            @include abstand($layout, margin, top, 40);
        }

        &__person-telefon,
        &__person-titelberuf {
            @include abstand($layout, margin, bottom, 18);
        }

        &__person-text {
            position: absolute;

            &--1 {
                margin-right: spalten($layout, 6, 10, 0, 8.8);
            }

            &--0 {
                margin-right: spalten($layout, 1, 10, 0, 6.7);
            }
        }
    }
}
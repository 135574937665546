.architektur {

    &__bild {
        filter: grayscale(60%);
        -webkit-filter: grayscale(60%);
        transition: 0.5s;
        width: 100%;
    }

    &__titel {
        font-family: "Kievit Serif W01 Regular", serif;
        font-weight: 400;
    }

    &__untertitel {
        font-family: ag-book, sans-serif;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .architektur {

        &__bild {
            width: 100%;
        }
        
        &__baute {
            @include abstand($layout, margin, alle, 10, 0, 10, 0);
            width: 100%;
        }
        
        &__link {
            text-decoration: none;
        }
        
        &__titel {
            @include schriftart($layout, 32, 36);
        }
        
        &__untertitel {
            @include schriftart($layout, 19, 23, 500);
        }
        
        &__container {
            @include abstand($layout, padding, alle, 20, 40, 100, 40);
            background-color: map-get($farben, hellbraun);
            color: black;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .architektur {
        @include abstand($layout, margin, alle, 16, 310, 16, 0);

        &__spaltenabstand {
            width: spalten($layout, 0, 10, 0, 2);
        }

        &__baute {
            width: spalten($layout, 5, 10);
        }

        &__link {
            text-decoration: none;
        }

        &__titel {
            @include abstand($layout, padding, alle, 30, 0, 7, 0);
            @include schriftart($layout, 40, 46);
        }

        &__untertitel {
            @include abstand($layout, padding, alle, 0, 0, 120, 0);
            @include schriftart($layout, 29, 34, 500);
        }

        &__container {
            background-color: map-get($farben, hellbraun);
            color: black;
            padding-left: spalten($layout, 1, 10, 0, 0);
            transition: 0.5s;
        }

        &__bild:hover ~ .architektur__container {
            background-color: white;
            transition: 0.5s;
        }

        &__container:hover {
            background-color: white;
            transition: 0.5s;
        }

        &__container ~ .architektur__bild {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
            transition: 0.5s;
        }

        &__bild:hover {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
            transition: 0.5s;
        }
    }
}
.introtext {

    &--braun {
        background-color: map-get($farben, hellbraun);
    }

    &--weiss {
        background-color: white;
        color: black;
    }

    &__titel {
        display: inline;
        font-family: ag-book, sans-serif;
        font-weight: 500;
    }
    
    .body--angebote & {
        &__text {
            font-family: ag-book, sans-serif;
        }
    }
    
    &__text {
        font-family: "Kievit Serif W01 Regular", serif;
        
        & p {
            
            &:first-of-type {
                display: inline;
            }
        }
    }
    


}


@include mediaquery(smartphone) {

    $layout: smartphone;


    .introtext {
        @include abstand($layout, padding, alle, 0, 40, 240, 40);
        padding-bottom: groesse($layout, 80);

        &__titel {
            @include schriftart($layout, 24, 29);
            margin-top: -1px;
        }

        &__text {
            @include schriftart($layout, 20, 25);

            & p {
                @include abstand($layout, margin, alle, 20, 0, 25, 0);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;


    .introtext {
        @include abstand($layout, margin, alle, 0, 310, 0, 0);
        height: groesse($layout, 630);

        &__titel {
            @include schriftart($layout, 40, 46);
        }

        &__text {
            @include abstand($layout, padding, alle, 80, 0, 0, 0);
            @include schriftart($layout, 40, 46);
            padding-left: spalten($layout, 1, 10, 0, 1);
            padding-right: spalten($layout, 2, 10, 0, 1);

            & p {
                @include abstand($layout, margin, alle, 30, 0, 45, 0);

            }
        }
    }
}
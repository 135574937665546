.angebote {
    font-family: ag-book, sans-serif;

    &__menue-button {
        font-weight: 500;
        
        &:hover,
        &[data-aktiv="true"] {
            background-color: black;
            color: white;
        }
    }

    &__haupttitel {
        font-weight: 500;
    }

    &__link {
        color: black;
        text-decoration: none;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .angebote {

        &__haupttitel {
            @include abstand($layout, margin, alle, 0, 40, 20, 40);
            @include abstand($layout, padding, bottom, 20);
            @include schriftart($layout, 29, 34);
            border-bottom: groesse($layout, 4) solid black;
            text-align: center;
        }

        &__menue {
            @include abstand($layout, margin, alle, 40, 0, 0, 0);
            display: flex;
            justify-content: center;
        }

        &__menue-button {
            @include abstand($layout, margin, alle, 0, 7, 0, 7);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 19, 39);
            align-items: center;
            background-color: white;
            background-color: white;
            border: 2px solid black;
            color: black;
            cursor: pointer;
            display: flex;
            height: groesse($layout, 30);
        }

        &__haeuser {
            @include abstand($layout, margin, alle, 0, 40, 100, 40);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            &--1 {
                @include abstand($layout, margin, top, 40);
            }
        }

        &__haus {
            @include abstand($layout, margin, alle, 0, -20, 0, -20);
            width: spalten($layout, 5, 5, 0, 4);

            &-extra {
                width: 100%
            }
        }

        &__titel {
            @include abstand($layout, margin, alle, 20, 20, 0, 20);
            @include schriftart($layout, 24, 29);
            width: spalten($layout, 5, 5, 0, -4);

            &-klein {
                @include schriftart($layout, 19, 23);
                @include abstand($layout, margin, alle, 20, 0, 0, 0);
                font-weight: 500;
            }
        }

        &__untertitel {
            @include abstand($layout, margin, alle, 0, 20, 0, 20);
            width: spalten($layout, 5, 5, 0, -4);

            &-beschreibung {
                @include schriftart($layout, 18, 23);
            }

            &-angebotsart {
                @include abstand($layout, margin, alle, 0, 0, 80, 0);
                @include schriftart($layout, 19, 23);
                font-weight: 500;
            }

            &-extra {
                @include abstand($layout, margin, bottom, 60);
            }

            &-ort {
                @include schriftart($layout, 19, 23);
                font-weight: 500;
            }
        }

        &__zusatztext {
            @include abstand($layout, margin, alle, 0, 40, 40, 40);
            @include schriftart($layout, 20, 23);
            text-align: center;

            &-link {
                color: black;
                font-weight: 500;
                text-decoration: none;
            }

            &-desktop {
                display: none;
            }

            &-mobile {
                display: show;
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .angebote {
        @include abstand($layout, margin, alle, 0, 310, 300, 0);

        &__center {
            display: flex;
            justify-content: center;
            margin-left: spalten($layout, 1, 10, 0, 1);
        }

        &__haupttitel {
            @include abstand($layout, margin, top, 45);
            @include abstand($layout, padding, bottom, 20);
            @include schriftart($layout, 56, 60);
            border-bottom: groesse($layout, 6) solid black;
        }

        &__menue {
            @include abstand($layout, margin, alle, 80, 0, 80, 0);
            display: flex;
            justify-content: center;
            margin-left: spalten($layout, 1, 10, 0, 1);
        }

        &__menue-button {
            @include abstand($layout, margin, alle, 0, 7.5, 0, 7.5);
            @include abstand($layout, padding, alle, 0, 30, 0, 30);
            @include schriftart($layout, 29, 29);
            align-items: center;
            background-color: white;
            border: 2px solid black;
            color: black;
            cursor: pointer;
            display: flex;
            height: groesse($layout, 50);
        }

        &__haeuser {
            
            &--1 {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-left: spalten($layout, 1, 10, 0, 1);
                margin-top: groesse($layout, 40);
            }
            
            &--2 {
                @include abstand($layout, margin, alle, 0, -26.5, 0, -26.5);
                display: flex;
                flex-wrap: wrap;
                padding-left: spalten($layout, 1, 10, 0, 1);
            }
        }

        &__haus {
            width: spalten($layout, 17, 36, 0, 0);

            &--eigentum,
            &--miete {
                @include abstand($layout, margin, bottom, 155);
            }

            &-extra {
                @include abstand($layout, margin, alle, 0, 26, 80, 26);
                width: spalten($layout, 12, 40, 0, 1);

            }
        }

        &__spaltenabstand {
            position: absolute;
            width: groesse($layout, 109);
        }

        &__titel {
            @include abstand($layout, margin, bottom, 20);
            @include schriftart($layout, 54, 60);

            &-klein {
                @include schriftart($layout, 29, 34);
                font-weight: 500;
            }
        }

        &__untertitel-beschreibung {
            @include schriftart($layout, 28, 34);
        }

        &__untertitel-ort {
            @include schriftart($layout, 29, 34);
            font-weight: 500;
        }

        &__untertitel-angebotsart {
            @include schriftart($layout, 29, 34);
            font-weight: 500;
        }

        &__zusatztext {
            @include abstand($layout, margin, alle, 40, 0, 75, 0);
            @include schriftart($layout, 29, 34);
            text-align: center;
            margin-left: spalten($layout, 1, 10, 0, 1);
        }

        &__zusatztext-link {
            color: black;
            font-weight: 500;
            text-decoration: none;
        }

        &__zusatztext-mobile {
            display: none;
        }

        &__bild {
            @include abstand($layout, margin, alle, 0, 0, 45, 0);
            width: 100%;
        }
    }
}
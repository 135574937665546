@include mediaquery(smartphone) {

    $layout: smartphone;

    .scrollpfeil {
        display: none;
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .scrollpfeil {
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        
        &__abstand {
            height: 100vh;
        }
        
        &__knopf {
            @include abstand($layout, margin, alle, 0, 0, 0, -160);
            background-color: transparent;
            border: transparent;
            cursor: pointer;
            position: fixed;
            position: sticky;
            transform: rotate(90deg);
            top: calc(100vh - 15vh);
            z-index: 1;
        }
        
        &-svg {
            width: groesse($layout, 30);
        }
    }
}

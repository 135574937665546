.atelier-artikel {

    &__hauptbild {

        &--1 {
            filter: grayscale(40%);
            -webkit-filter: grayscale(40%);
            width: 100%;
        }

        &--2 {
            width: 100%;
        }
    }

    &__container--0 {
        background-color: map-get($farben, hellbraun);
    }
    
    &__titel {
        font-family: ag-book, sans-serif;
        font-weight: 500;
    }
    
    &__untertitel {
        font-family: "Kievit Serif W01 Regular", serif;
        font-weight: 400;
    }
    
    &__text {
        font-family: "Kievit Serif W01 Regular", serif;
    }
    
    
    &__zitat-text {
        font-family: "Kievit Serif W01 Regular", serif;
    }
    
    &__zitat-bild {
        border-radius: 100%;
        overflow: hidden;
    }

    &__zitat-quelle {
        font-family: ag-book, sans-serif;
        font-weight: 500;
    }

    &__zitat-bild {
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .atelier-artikel {

        &__hauptbild {

            &--2 {
                @include abstand($layout, padding, bottom, 100);
                background-color: black;
                background-color: map-get($farben, hellbraun);
            }
        }

        &__container {
            @include abstand($layout, padding, alle, 0, 40, 20, 40);
        }

        &__titel {
            @include schriftart($layout, 19, 23);
            border-bottom: 4px solid black;
            display: inline-block;
            left: 50%;
            margin-bottom: groesse($layout, 20);
            margin-top: groesse($layout, 60);
            position: relative;
            transform: translateX(-50%);
        }

        &__untertitel {
            @include schriftart($layout, 32, 36);
            display: flex;
            justify-content: center;
            text-align: center;
        }

        &__inhalt {
            @include schriftart($layout, 18, 22);
            margin-bottom: groesse($layout, 60);
            margin-top: groesse($layout, 60);
        }

        &__text {
            margin-bottom: groesse($layout, 60);
            margin-top: groesse($layout, 60);
        }

        &__bild {
            @include abstand($layout, margin, alle, 60, 0, 60, -20);
            width: spalten($layout, 5, 5, 0, 4);
        }

        &__zitat-text {
            @include schriftart($layout, 24, 29);
        }

        &__zitat-quelle {
            @include schriftart($layout, 19, 29);
        }

        &__zitat-bild {
            margin-left: spalten($layout, 3, 5, 0, 1);
            width: spalten($layout, 2, 5, 0, 0);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .atelier-artikel {

        &__container--0 {
            
            .atelier-artikel__bild {
                padding-bottom: groesse($layout, 60);
                width: spalten($layout, 10, 20, 0, 1);
            }
            
            .atelier-artikel__inhalt {
                display: flex;
                flex-direction: row-reverse;
            }
            
            .atelier-artikel__text {
                @include schriftart($layout, 24, 29);
                margin-right: spalten($layout, 1, 20, 0, 1);
                padding-left: spalten($layout, 1, 20, 0, 2);
                width: spalten($layout, 7, 20, 0, 1);
            } 
        }

        &__hauptbilder {
            @include abstand($layout, margin, alle, 0, 310, 0, 0);
        }

        &__container {
            @include abstand($layout, margin, alle, 0, 310, 0, 0);
            @include abstand($layout, padding, alle, 150, 0, 0, 0);
        }

        &__container--1 {
            
            .atelier-artikel__bild {
                display: flex;
                justify-content: flex-end;
                padding-bottom: groesse($layout, 60);
                width: spalten($layout, 10, 20, 0, 0);
            }

            .atelier-artikel__text {
                @include schriftart($layout, 24, 29);
                margin-right: spalten($layout, 1, 20, 0, 2);
                padding-bottom: groesse($layout, 60);
                width: spalten($layout, 7, 20, 0, 0);
            }
            
            .atelier-artikel__inhalt {
                display: flex;
                justify-content: flex-end;
                padding-bottom: groesse($layout, 60);
            }
        }

        &__hauptbild {

            &--2 {
                @include abstand($layout, padding, bottom, 300);
                background-color: map-get($farben, hellbraun);
            }
        }
        
        &__titel {
            @include schriftart($layout, 29, 34);
            display: inline-block;
            left: 50%;
            margin-bottom: groesse($layout, 50);
            padding-bottom: groesse($layout, 15);
            padding-top: groesse($layout, 15);
            position: relative;
            transform: translateX(-50%);

            &::before {
                content: "";
                background-color: black;
                height: groesse($layout, 6);
                left: 0;
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }

        &__untertitel {
            @include schriftart($layout, 60, 66);
            padding-bottom: groesse($layout, 65);
            text-align: center;
        }

        &__zitat {
            display: flex;
            align-items: flex-start;
            padding-bottom: groesse($layout, 100);
        }

        &__zitat-text {
            @include schriftart($layout, 40, 46);
            margin-left: spalten($layout, 2, 10, 0, 1);
            width: spalten($layout, 8, 20, 0, 0);
        }

        &__zitat-quelle {
            @include schriftart($layout, 29, 46);
        }

        &__zitat-bild {
            margin-left: spalten($layout, 1, 20, 0, 2);
            width: spalten($layout, 3, 20, 0, 0);
        }
    }
}